import React, { useRef, useEffect } from "react";
import { Link } from "gatsby";

//Components
import Layout from "../../components/Layout";
import Category from "../../components/Category";

const data = [
  {
    title: "Urología y endourología",
    subsubCategories: [
      { title: "Catéter doble J", subsubsubCategories: [] },
      { title: "Set de nefrostomía percutánea", subsubsubCategories: [] },
      {
        title: "Canastillas para recepción de cálculos",
        subsubsubCategories: [],
      },
      {
        title:
          "Kit vesical, bolsas de orina, sondas Foley, fijadores de sonda, ",
        subsubsubCategories: [],
      },
    ],
  },
  {
    title: "Biopsias",
    subsubCategories: [
      {
        title:
          "Pistolas reusables y desechables, marcajes, agujas (hígado, mama, páncreas, próstata, pulmón, riñón) coaxiales.",
        subsubsubCategories: [],
      },
    ],
  },
  {
    title: "Cirugía general",
    subsubCategories: [
      {
        title:
          "Mallas para hernioplastia tradicional y vía laparoscópica (ventral, inguinal, hiato, abdominal) ",
        subsubsubCategories: [],
      },
      {
        title: "Sistema de sujeción permanente y reabsorbible",
        subsubsubCategories: [],
      },
      {
        title:
          "Sistemas de drenaje tipo Jackson pratt, y drenovac, y tipo blake",
        subsubsubCategories: [],
      },
      {
        title: "Engrapadora para piel, surgiclip, endobag, endostich, endoclip",
        subsubsubCategories: [],
      },
      {
        title: "Trocares, hemolok, camisa, Alexis, gelpoint",
        subsubsubCategories: [],
      },
      {
        title: "Cartucho lineal",
        subsubsubCategories: [],
      },
      {
        title: "Ligasure",
        subsubsubCategories: [],
      },
      {
        title: "Armónico ",
        subsubsubCategories: [],
      },
      {
        title: "Torre laparoscópica/Electrocauterio, lápiz reusable y placas",
        subsubsubCategories: [],
      },
    ],
  },
  {
    title: "Cuidado avanzado de heridas",
    subsubCategories: [
      {
        title: "Presión negativa",
        subsubsubCategories: [],
      },
      {
        title: "Apósitos de barrera antimicrobiana con plata nanocristalina",
        subsubsubCategories: [],
      },
    ],
  },
  {
    title: "Accesos vasculares",
    subsubCategories: [
      {
        title:
          "Puertos para terapia tipo port-a-cath, agujas gripper, catéter PICC guiado por ultrasonido, catéter crónico y agudo para hemodiálisis, fistulas e injertos vasculares FAVI",
        subsubsubCategories: [],
      },
    ],
  },
  {
    title: "Cardiovascular",
    subsubCategories: [
      {
        title:
          "Stent coronario semi complaciente de intercambio rápido recubierto con polímero bio absorbible con fármaco de rapamicina",
        subsubsubCategories: [],
      },
      {
        title:
          "Balones de intercambio rápido semi complaciente de PTCA para dilatación coronaria.",
        subsubsubCategories: [],
      },
    ],
  },
];

const DispMedicos = () => {
  return (
    <Layout>
      <Category
        data={data}
        title="Proyectos integrales/Servicios de mantenimiento"
      />
    </Layout>
  );
};

export default DispMedicos;
